<template>
  <div class="vpt-full-vp">
    <div class="vpt-header">
        <router-link to="/" class="logo vpt-router-link">
            <img src="./assets/img/header/logo.svg" alt="">
        </router-link>
        <div class="medium">
          <router-link exact to="/products" class="item vpt-router-link">Products</router-link>
          <router-link exact to="/support" class="item vpt-router-link">Support</router-link>
          <router-link exact to="/news" class="item vpt-router-link">News</router-link>
          <router-link exact to="/contact" class="item vpt-router-link">Contact</router-link>
          <router-link exact to="/about" class="item vpt-router-link">About</router-link>
        </div>
        <div class="right">
            <!--
            <div class="item" @click="cnZn">
              <span>{{ znzn }}<i class="iconfont">&#xe608;</i></span>
            </div>
            -->
            <div class="item highlight" ref="memberNavMenuTrigger" @click="onMemberButtonClicked">
              <i class="iconfont">&#xe639;</i>
            </div>
            <router-link exact to="/demo" class="item highlight vpt-router-link">
              <i class="iconfont">&#xe88f;</i>
            </router-link>
        </div>
    </div>

    <div class="vpt-header-mobile">
      <div class="menu-trigger" ref="mobileNavMenuTrigger" @click="openMobileNavMenu">
          <i class="iconfont">&#xe610;</i>
      </div>
      <div class="logo">
          <router-link to="/" class="vpt-router-link">
              <img src="./assets/img/header/logo.svg" alt="">
          </router-link>
      </div>
      <div class="menu-trigger" ref="mobileMemberNavMenuTrigger" @click="onMobileMemberButtonClicked">
          <i class="iconfont">&#xe639;</i>
      </div>
    </div>

    <vpt-popup-menu ref="memberNavMenu" sticky>
      <div class="vpt-nav-menu">
        <router-link v-if="$store.state.isAuthenticated" exact to="/orders" class="item vpt-router-link">Orders</router-link>
        <router-link v-if="$store.state.isAuthenticated" exact to="/shippingAddresses" class="item vpt-router-link">Shipping Addresses</router-link>
        <router-link v-if="$store.state.isAuthenticated" exact to="/accountSettings" class="item vpt-router-link">Account Settings</router-link>
        <router-link v-if="!$store.state.isAuthenticated" exact to="/login" class="item vpt-router-link">Sign In</router-link>
        <router-link v-if="!$store.state.isAuthenticated" exact to="/emailJoin" class="item vpt-router-link">Sign Up</router-link>
        <div v-if="$store.state.isAuthenticated" class="item" @click="logout">Log Out</div>
      </div>
    </vpt-popup-menu>

    <vpt-popup-menu ref="mobileNavMenu">
      <div class="vpt-mobile-nav-menu">
        <router-link exact to="/products" class="item vpt-router-link">
          <i class="iconfont">&#xe624;</i>
          <div class="_title">Products</div>
        </router-link>
        <router-link exact to="/support" class="item vpt-router-link">
          <i class="iconfont">&#xe88b;</i>
          <div class="_title">Support</div>
        </router-link>
        <router-link exact to="/news" class="item vpt-router-link">
          <i class="iconfont">&#xe67e;</i>
          <div class="_title">News</div>
        </router-link>
        <router-link exact to="/contact" class="item vpt-router-link">
          <i class="iconfont">&#xe7f0;</i>
          <div class="_title">Contact</div>
        </router-link>
        <router-link exact to="/about" class="item vpt-router-link">
          <i class="iconfont">&#xe63c;</i>
          <div class="_title">About</div>
        </router-link>
      </div>
    </vpt-popup-menu>

    <vpt-popup-menu ref="mobileMemberNavMenu">
      <div class="vpt-mobile-nav-menu">
        <router-link v-if="$store.state.isAuthenticated" exact to="/orders" class="item vpt-router-link">
          <i class="iconfont">&#xe667;</i>
          <div class="_title">Orders</div>
        </router-link>
        <router-link v-if="$store.state.isAuthenticated" exact to="/shippingAddresses" class="item vpt-router-link">
          <i class="iconfont">&#xe606;</i>
          <div class="_title">Shipping<br>Addresses</div>
        </router-link>
        <router-link v-if="$store.state.isAuthenticated" exact to="/accountSettings" class="item vpt-router-link">
          <i class="iconfont">&#xe628;</i>
          <div class="_title">Account<br>Settings</div>
        </router-link>
        <router-link v-if="!$store.state.isAuthenticated" exact to="/login" class="item vpt-router-link">
          <i class="iconfont">&#xe623;</i>
          <div class="_title">Sign In</div>        
        </router-link>
        <router-link v-if="!$store.state.isAuthenticated" exact to="/emailJoin" class="item vpt-router-link">
          <i class="iconfont">&#xe6d6;</i>
          <div class="_title">Sign Up</div>
        </router-link>
        <div v-if="$store.state.isAuthenticated" class="item" @click="logout">
          <i class="iconfont">&#xe65d;</i>
          <div class="_title">Log Out</div>        
        </div>
      </div>
    </vpt-popup-menu>

    <router-view>
    </router-view>
  </div>  
  <div class="vpt-footer wow animate__animated animate__fadeInUp">
    <div class="up">
        <div class="_left">
            <div class="co">
              Shenzhen Viewpoint Innovation Technology Co.
            </div>
            <div class="desc-item">
              <i class="iconfont">&#xe66f;</i><a  class="content" href="mailto:admin@viewpt.cn">admin@viewpt.cn</a>
            </div>
            <div class="desc-item">
              <i class="iconfont">&#xe606;</i>
              <div>Room A1, 4th Floor, TOWER13A Building, Point City, No. 446 Jihua Road, Longgang District, Shenzhen, Guangdong Province, China</div>
            </div>
        </div>
        <div class="right">
          <router-link exact to="/" class="item vpt-router-link">Home</router-link>
          <router-link exact to="/products" class="item vpt-router-link">Product</router-link>
          <router-link exact to="/support" class="item vpt-router-link">Support</router-link>
          <router-link exact to="/news" class="item vpt-router-link">News</router-link>
          <router-link exact to="/contact" class="item vpt-router-link">Contact</router-link>
          <router-link exact to="/about" class="item vpt-router-link">About</router-link>
          <router-link v-if="$store.state.isAuthenticated" exact to="/orders" class="item vpt-router-link">Orders</router-link>
          <router-link v-if="$store.state.isAuthenticated" exact to="/shippingAddresses" class="item vpt-router-link">Addresses</router-link>
          <router-link v-if="!$store.state.isAuthenticated" exact to="/login" class="item vpt-router-link">Sign In</router-link>
          <router-link v-if="!$store.state.isAuthenticated" exact to="/emailJoin" class="item vpt-router-link">Sign Up</router-link>
        </div>
    </div>
    <div class="down wow animate__animated animate__fadeInUp">
      <div class="copyright">
          Copyright © 2024 VIEWPT Co. All rights reserved.
      </div>
      <div class="clause">
        <div class="item">
            Privacy
        </div>
        <div class="item">
            Teams of Service
        </div>
        <div class="item">
            Cookies Settings
        </div>
      </div>
      <div class="filings">
        <a href="https://beian.miit.gov.cn/">粤ICP备17102858号</a>
      </div>
    </div>
  </div>
</template>

<script setup>

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { logout as _logout } from '@/api'
import VptPopupMenu from '@/components/VptPopupMenu.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

const memberNavMenuTrigger = ref(null)
const memberNavMenu = ref(null)
const mobileNavMenuTrigger = ref(null)
const mobileNavMenu = ref(null)
const mobileMemberNavMenuTrigger = ref(null)
const mobileMemberNavMenu = ref(null)

function onMemberButtonClicked() {
  memberNavMenu.value.open(memberNavMenuTrigger.value)
}

function openMobileNavMenu() {
  mobileNavMenu.value.open(mobileNavMenuTrigger.value)
}

function onMobileMemberButtonClicked() {
  mobileMemberNavMenu.value.open(mobileMemberNavMenuTrigger.value)
}

function logout() {
  store.commit("setLogout")
  _logout()
  router.push('/')
}

</script>

<style lang="scss">

@font-face {
  font-family: 'iconfont';  /* Project id 4389177 */
  src: url('./assets/fonts/iconfont.woff2') format('woff2'),
        url('./assets/fonts/iconfont.woff') format('woff'),
        url('./assets/fonts/iconfont.ttf') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "MON";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "MONL";
  src: url("./assets/fonts/Montserrat-Light-6.otf");
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* Firefox */
    -webkit-box-sizing: border-box;
    /* Safari */
    margin: 0px;
    padding: 0px;
    outline: none;
}

html, body {
  width: 100%;
  //overflow-x: hidden;
}

body {
  background-color: #f4f4f4;
  font-family: 'MON';
}

/* 宽度 */
::-webkit-scrollbar {
    width: 5px;
}

/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius:10px;
  -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

.title {
    margin:  8.125rem 0 3.5rem 0;
    font-family: 'MON';
}

.center {
    text-align: center;
}

.left {
    margin-left: 4rem;
}


.vpt-router-link {
  text-decoration: none;
  color: #000000;
}

.vpt-body {
	width: 98%;
  max-width: 1880px;
	margin: 0 auto;
	height: auto;
}

.vpt-full-vp {
  min-height: 100vh;
}

.vpt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 500;
  background-color: #f4f4f4;
  width: 100%;
  padding: 1.5rem 0;
  .logo {
    img {
      height: 2.5rem;
    }
  }
  .medium {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
  }  
  .right {
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
  }
  .item {
    border-radius: 1rem;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    &:hover {
      background-color: #e3dddd;
      transition:background-color 0.5s;
    }
    &.highlight {
      background-color: #000000;
      color: #FFFFFF;
      &:hover {
        background-color: rgb(0, 0, 0, 0.5);
        transition:background-color 0.5s;
      }
    }
    i {
        font-size: 1.5rem;
    }
  }  
}

.vpt-header-mobile {
  display: none;
}

.vpt-nav-menu {
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  border: 1px solid rgb(0, 0, 0, 0.3);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  .item {
    color: black;  
    padding: 1rem 1rem;
    border-radius: 1rem;
    font-size: 1.75rem;
    font-weight: bold;
    text-align: right;
    &:hover {
      background-color: #e3dddd;
      transition:background-color 0.5s;
    }
  }
}

.vpt-mobile-nav-menu {
  //background-color: #ffffff;
  padding: 1rem 1.5rem;
  //border: 1px solid rgb(0, 0, 0, 0.3);
  //border-radius: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    color: white;  
    padding: 1rem 1rem;
    border-radius: 1rem;
    &:hover {
      background-color: #e3dddd;
      transition:background-color 0.5s;
    }
    i {
      font-size: 3rem;
    }
    ._title {
      font-size: 1.75rem;
      font-weight: bold;
      text-align: center;
    }
  }
}

.vpt-footer {
    background-color: #FFFFFF;
    margin-top: 1.5rem;
    border-top-right-radius: 1.25rem;
    border-top-left-radius: 1.25rem;
    font-size: 1.25rem;
    .up {
        padding: 3.75rem 4rem 3.75rem 3.75rem;
        display: flex;
        ._left {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;
          width: 50%;
          padding-right: 1rem;
          .co {
            font-size: 2rem;
          }
          .desc-item {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            column-gap: 1rem;
            i {
              font-size: 1.2rem;
              color: #666666;
            }
          }
        }
        .right {
            width: 50%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
            padding-left: 1rem;
            .item {
              width: 9rem;
              &:hover {
                color: rgb(0, 0, 0, 0.5);
                transition:color 0.5s;
              }
            }
        }
    }
    .down {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        row-gap: 0.5rem;
        background-color: #000000;
        color: #FFFFFF;
        border-top-right-radius: 1.25rem;
        border-top-left-radius: 1.25rem;
        padding: 1rem 0rem;
        .clause {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 2rem;
        }
        .filings {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 2rem;
        }
    }
}

@media only screen and (max-width:1680px) {
  html {
    font-size: 16px !important;
  }
}

@media only screen and (max-width:1440px) {
  html {
    font-size: 12px !important;
  }
}

@media only screen and (max-width:1280px) {
  html {
    font-size: 12px !important;
  }
}

@media only screen and (max-width:1024px) {
  html {
    font-size: 10px !important;
  }
}

@media only screen and (max-width:720px) {

  html {
    font-size: 10px !important;
  }  

  h1 {
      font-size: 2rem;
  }

  //.vpt-title {
  //    margin:  4rem 0 2rem 0;
  //}

  .center{
      margin-left: 1rem;
  }

  .vpt-header {
    display: none !important;
  }

  .vpt-header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 500;
    background-color: #f4f4f4;  
    padding: 0.5rem 0;
    .logo {
      text-align: center;
      img {
        width: 45%;
      }
    }
    .menu-trigger {
      i {
        font-size: 3rem;
      }
    }
  }

  .vpt-footer {
    background-color: #FFFFFF;
    margin-top: 1.5rem;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    font-size: 0.75rem;

    .up {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding: 1rem 1rem;
        ._left {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          padding-right: 0;
          width: 100%;
          .co {
            font-size: 1.25rem;            
          }
          .desc-item {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            row-gap: 0rem;
            column-gap: 0.5rem;
            i {
              color: #666666;
              font-size: 1rem;
            }
          }
        }
        .right {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
            row-gap: 0.3rem;
            width: 100%;
            border-top: thin solid rgb(0, 0, 0, 0.3);
            padding: 0.3rem 0 0 0;
            .item {
              width: 5rem;
              &:hover {
                color: rgb(0, 0, 0, 0.5);
                transition:color 0.5s;
              }
            }
        }
    }
    .down {
      row-gap: 0.3rem;
      border-top-right-radius: 0.75rem;
      border-top-left-radius: 0.75rem;
      padding: 0.5rem 0rem;
      .clause {
          column-gap: 0.5rem;
      }
      .filings {
          column-gap: 0.5rem;
      }
    }
  }

}


</style>